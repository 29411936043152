section.servicesH
  background-color: lightgray
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  .content
    width: 100%
    align-items: center
    padding: 10% 0
    .arrow
      display: none
    @media (min-width: 768px)
      padding: 7% 0
    @media (min-width: 1280px)
      padding: 5% 0
      width: 90%
      margin: 0 auto
      .arrow
        display: block
        z-index: 2
        top: 65%
        width: 30px
        cursor: pointer
        transform: translateY(-50%)
        @media (min-width: 1366px)
          width: 36px
      .backSer
        left: -30px
      .nextSer
        right: -30px
    @media (min-width: 1440px)
      width: 85%
    @media (min-width: 1600px)
      width: 80%
    @media (min-width: 1900px)
      width: 75%
      .arrow
        display: none
    h1
      font-weight: 400
      line-height: 1.05
      text-align: center
      margin-bottom: 1rem
      letter-spacing: 7px
      text-transform: uppercase
      span
        font-size: 32px
        font-weight: 600
    p
      width: 70%
      @media (min-width: 768px)
        width: 50%
        text-align: center
      @media (min-width: 1024px)
        width: 30%
  .content .slickServicesH
    width: 100% 
    margin: 2rem 0 0
    @media (min-width: 1024px)
      margin: 3rem 0 0
    @media (min-width: 1280px)
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin: 4rem 0 0
    @media (min-width: 1900px)
      flex-direction: row
      gap: 0 3rem
    .slick-dots
      margin-top: 1rem
    .slick-slide
      margin: 0 1rem
    .item
      display: flex!important
      border-radius: 30px
      overflow: hidden
      background: $white
      @media (max-width: 450px)
        width: 200px!important
      @media (min-width: 1900px)
        flex: 1
      img
        width: 100%
        height: 190px
        // object-fit: cover
      span
        color: $mainColor
        padding: 2rem
        text-align: center
        font-weight: 500
      @media (min-width: 1280px)
        background: none
        border: 1px solid $mainColor
        transition: background .3s
        span
          color: $white
          // height: 3.2rem
          height: calc( 3.2rem + 4rem )
        &:hover
          background: $white
          border: none
          span
            color: $mainColor


