@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap')

$mainColor: #094EAE
$secondColor: #25D366
$white: #fff
$whatsapp: #25D366

//fonts
$font-principal: 'Rajdhani', sans-serif

$textColor: #63696E
