section.bannerHome
  .slick-dots
    margin: -2rem 0 calc( 2rem -  6px)
  .arrow
    top: 50%
    transform: translateY(-50%)
    z-index: 2
    cursor: pointer
    @media (min-width: 1280px)
      display: block
    @media (min-width: 1366px)
      width: 36px
  .backBan
    left: 3%
  .nextBan
    right: 3%
  .content
    width: 100%
  .slick-slide
    div
      margin-bottom: -6px
  picture
    width: 100%
    img
      width: 100%