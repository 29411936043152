section.contact
  padding: 10% 0
  @media (min-width: 1024px)
    padding: 0 0 0 7.5%
section.contact .content
  width: 85%
  margin: 0 auto
  align-items: center
  @media (min-width: 768px)
    width: 70%
  @media (min-width: 1024px)
    width: 100%
    flex-direction: row
    align-items: flex-start
    justify-content: space-between
  .info
    width: 100%
    @media (min-width: 1024px)
      width: 35%
      padding-top: 5%
    @media (min-width: 1280px)
      padding-top: 6%
    h2, h3
      color: $mainColor
      text-transform: uppercase
    h3
      font-weight: 500
    h2
      font-weight: 400
      letter-spacing: 9px
      @media (min-width: 1280px)
        font-size: 26px
      @media (min-width: 1440px)
        font-size: 30px
    p
      color: #020811
    &>p
      margin: 2px 0 1rem
      @media (min-width: 1280px)
        font-size: 18px
        margin: 2px 0 2rem
      @media (min-width: 1440px)
        font-size: 20px
      @media (min-width: 1600px)
        margin: 2px 0 3rem
    form
      margin: 1rem 0 2rem
      gap: 1rem
      input, textarea
        width: 100%
        border: 1px solid $mainColor
        color: $mainColor
        border-radius: 15px
      input 
        padding: 0 10px
        height: 2.5rem
        &::placeholder
          color: #63696E
      textarea
        padding: 5px 10px
        resize: none
      @media (min-width: 1280px)
        flex-direction: row
        flex-wrap: wrap
        justify-content: space-between
        input
          width: 48%
      @media (min-width: 1440px)
        margin: 1rem 0 3rem
      .policy
        width: 100%
        align-items: center
        gap: 0 1rem
        p
          color: #63696E
          a
            color: $mainColor
            text-decoration: underline
            font-weight: 700
        @media (min-width: 1366px)
          p, a
            font-size: 14px 
        .btnFormContact
          background: $mainColor
          border-radius: 20px
          color: $white
          padding: 12px 30px
          font-weight: 500
          transition: .2s
          cursor: pointer
          &:hover
            transform: scale(1.05)
        @media (min-width: 768px)
          flex-direction: row
          justify-content: space-between
          align-items: flex-start
          p
            width: 55%
    .directory
      align-items: flex-start
      gap: 1rem
      @media (min-width: 1280px)
        flex-direction: row
        flex-wrap: wrap
        justify-content: flex-start
        gap: 1rem 3rem
      .item
        gap: 8px
        // margin-bottom: 1.5rem
        align-self: start
        .social
          align-items: center
          gap: 0 1rem
        // &:last-child
        //   width: 100%
      .item h3
        font-weight: 500
        letter-spacing: 4px
        font-size: 20px
      .item p
        margin: 0
  &>img
    display: none
    @media (min-width: 1024px)
      display: block
      width: 65%  
    @media (min-width: 1600px)
      width: 55%  