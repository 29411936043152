.modalFilter
  padding: 0 !important
  width: 95%!important
  height: 98%
  border-radius: 15px!important
  @media (min-width: 1280px)
    max-width: 700px
  .content
    height: 100%
    width: 100%
    .tittle
      justify-content: center
      align-items: center
      width: 100%
      padding: 2rem 0
      background: $mainColor
      h2
        text-transform: uppercase
        text-align: center
        font-weight: 400
        color: $white
        font-size: 34px
        letter-spacing: 9px
      a
        top: 5px
        right: 5px
        width: 35px
        &:hover
          cursor: pointer
        img
          width: 100%
    .contentFilters
      flex: 1
      overflow: auto
      padding: 2rem
      @media (min-width: 1280px)
        padding: 2rem 3rem
      .accTittle, .accContent
        width: 100%
      .rotateImg img
        transform: rotate(180deg)
      .accContent
        height: 0
        overflow: hidden
        opacity: 0
        flex-direction: column
        align-items: flex-start
        border-bottom: 1px solid #c4c4c4
        display: none
      .accContent .selectors
        gap: 1rem
        flex-wrap: wrap
        label
          color: #535353
          font-size: 13px
          display: flex
          align-items: center
          gap: 0 5px
        label input[type=checkbox]
          // -webkit-appearance: none
          border-radius: 2px
          border: none
          height: 1.1rem
          width: 1.1rem
          position: relative
          // background-color: #f2f2f2
          border: 1px solid #c4c4c4
          accent-color: $mainColor
          &:checked
            accent-color: $mainColor  
          // background-color: $mainColor
          //   &:before
          //     content: "✔"
          //     position: absolute
          //     top: 1px
          //     left: 0
          //     width: 100%
          //     height: 100%
          //     line-height: 1.1em
          //     text-align: center
          //     color: #fff
      .accContent .brands
        margin-top: 1rem
        h5
          color: $mainColor
          font-weight: 500
          margin-bottom: .5rem
          font-size: 16px
      .accTittle
        justify-content: space-between
        align-items: center
        cursor: pointer
        margin-bottom: 1rem
        h4
          color: $mainColor
          font-size: 18px
        .rotater
          transition: 0.2s transform ease
      .openedContent
        height: auto
        overflow: visible
        opacity: 1
        transition: .3s opacity ease
        padding: 0 0 1.5rem
        margin-bottom: 1rem
    .apply  
      place-items: center
      padding: 2rem 0
      background: $mainColor
      a
        padding: .7rem 2.5rem
        color: $mainColor
        background: $white
        text-align: center
        border-radius: 20px
        font-weight: 600
        text-transform: uppercase
        &:hover
          cursor: pointer







