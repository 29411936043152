section.infoProduct .backDesk
  @media (min-width: 768px)
    margin-top: 4rem
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 0 1rem
    padding-left: 7.5%
    span
      color: #020811
section.infoProduct .container
  align-items: center
  padding: 10% 0
  @media (min-width: 768px)
    padding: 3% 0 6%
  @media (min-width: 1024px)
    flex-direction: row
    align-items: flex-start
    justify-content: space-between
  @media (min-width: 1366px)
    padding: 3% 0 5%
    width: 80%
  @media (min-width: 1440px)
    width: 75%
  @media (min-width: 1900px)
    padding: 3% 0 4%
    width: 70%
  .backPortfolio
    align-items: center
    justify-content: space-between
    width: 100%
    margin-bottom: 2rem
    @media (min-width: 768px)
      display: none
    .backButton
      align-items: center
      border-radius: 20px
      padding: 12px 20px
      gap: 0 .5rem
      background: rgba(131, 80, 255, 0.05)
      span
        color: #172942
        font-weight: 500
    .openModalShare
      width: 40px
      img
        width: 100%
  .infoLeft
    width: 100%
    margin-bottom: 1.5rem
    @media (min-width: 1024px)
      width: 55%
      h2, span
        display: none
    @media (min-width: 1366px)
      width: 50%
    @media (min-width: 1900px)
      width: 45%
    h2
      font-weight: 400
    span
      color: #535353
    .gallery
      width: 100%
      margin-top: 1rem
      @media (min-width: 1024px)
        margin: 0
      a
        align-items: center
        background: $white
        border-radius: 15px
        color: #020811
        border: 1px solid #c4c4c4
        gap: 5px
        padding: 10px 15px
        left: 10px
        top: 10px
        z-index: 1
        img
          width: 15px
      .sliderBig
        border: 1px solid #c4c4c4
        border-radius: 15px
        overflow: hidden
        img
          object-fit: contain
          height: 350px
      .sliderBig, .asnavSliderBig
        width: 100% 
      .asnavSliderBig
        margin-top: 1rem
      .asnavSliderBig .slick-slide
        border: 1px solid #c4c4c4
        border-radius: 8px
        margin: 0 5px
        img
          object-fit: contain
          height: 80px
          
  .details
    @media (min-width: 1024px)
      width: 35%
      p
        margin: 1rem 0 2rem
        white-space: pre-wrap
        overflow-wrap: break-word
    @media (min-width: 1366px)
      width: 40%
    h2, &>span
      display: none
      @media (min-width: 1024px)
        display: inline-flex
    h2
      font-weight: 400
      @media (min-width: 1280px)
        font-size: 30px
      @media (min-width: 1440px)
        font-size: 34px
      @media (min-width: 1600px)
        font-size: 36px
    h2, p
      color: #020811
    span
      color: #535353
    .features
      width: 100%
      justify-content: space-between
      align-items: center
      margin-top: 1rem
      padding-bottom: 2rem
      border-bottom: 1px solid #c4c4c4
      @media (min-width: 1024px)
        margin: 0
      .item
        justify-content: space-between
        align-items: flex-end
        gap: 0 1.5rem
        .info
          align-items: flex-start
          gap: .5rem 0
          @media (min-width: 1366px)
            align-items: center
          span
            color: #020811
          img
            width: 15px
        a
          place-items: center
          background: #c4c4c4
          border-radius: 50%
          width: 32px
          height: 32px
          transition: background .2s
          &:hover
            background: $mainColor
        @media (min-width: 1366px)
          align-items: center
          .info 
            flex-direction: row
            gap: 0 8px
            span br
              display: none

        
    .actions
      justify-content: space-between
      margin-top: 1.5rem
      @media (min-width: 768px)
        justify-content: flex-start
        gap: 0 1.2rem
      a:not(:last-child)
        border: 1px solid $mainColor
        align-items: center
        gap: 0 0.8rem
        padding: 0.5rem 1.5rem
        border-radius: 20px
        font-size: 12px
        span
          color: #020811
      a:last-child
        width: 40px
        img
          width: 100%


.menuFixed
  width: 100%
  background: $white
  position: fixed
  left: 0
  bottom: 0
  padding: 1rem 0 1.2rem
  z-index: 3
  box-shadow: 0 -10px 20px 0 rgba(131, 80, 255, 0.0)
  .container
    justify-content: space-around
    a
      border: 1px solid $mainColor
      align-items: center
      gap: 0 0.8rem
      padding: 0.5rem 1.5rem
      border-radius: 20px
      font-size: 12px
    span
      color: #020811
  @media (min-width: 768px)
    display: none