section.portfolioH
  background-color: lightgray
  background-position: center
  background-repeat: no-repeat
  background-size: cover
section.portfolioH .content
  align-items: center
  padding: 10% 0
  @media (min-width: 768px)
    padding: 7% 0
  @media (min-width: 1280px)
    padding: 5% 0
    width: 90%
    margin: 0 auto
  @media (min-width: 1366px)
    width: 85%
  @media (min-width: 1600px)
    width: 80%
  @media (min-width: 1900px)
    width: 75%
  .tittle
    justify-content: center
    h1
      font-weight: 400
      letter-spacing: 7px
      text-transform: uppercase
    a
      display: none
    @media (min-width: 1280px)
      width: 100%
      justify-content: space-between
      align-items: center
      a
        display: flex
        background: $white
        border-radius: 20px
        align-items: center
        justify-content: space-between
        padding: 10px 12px
        gap: 0 1.5rem
        img
          width: 15px
        span
          color: #020811
          font-weight: 500
  .itemsPort
    width: 100%
    margin: 2rem 0
    @media (min-width: 1280px)
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin: 3rem 0 0
    .slick-slide
      margin: 0 10px
    .item
      display: flex!important
      align-items: center
      justify-content: center
      width: 140px
      height: 130px
      border-radius: 20px
      background: rgba(9, 78, 174, .2)
      gap: 1rem 0
      transition: background-color .2s
      span
        text-align: center
        font-size: 12px
        font-weight: 500
      .image img:last-child
        display: none
      @media (min-width: 1280px)
        background: none
        height: auto
        padding: 1rem
        span
          font-size: 14px
          transition: opacity .2s
          @media (min-width: 1440px)
            font-size: 16px
        .arrow
          display: grid
          place-items: center
          border-radius: 15px
          padding: 8px 1rem
          background: $mainColor
          opacity: 0
          img
            width: 20px
        &:hover
          background: $white
          span
            color: #020811
          .arrow
            opacity: 1
        .image
          background: $white
          border-radius: 50%
          height: 100px
          width: 100px
          justify-content: center
          align-items: center
          img
            height: auto
        .image img:first-child
          display: none
        .image img:last-child
          display: block
        &:nth-child(1) .image img
          width: 26px
        &:nth-child(2) .image img
          width: 35px
        &:nth-child(3) .image img
          width: 35px
        &:nth-child(4) .image img
          width: 40px
        &:nth-child(5) .image img
          width: 28px
        &:nth-child(6) .image img
          width: 26px
  &>a
    background: $mainColor
    border-radius: 30px
    padding: 10px 12px
    align-items: center
    justify-content: center
    gap: 0 10px
    @media (min-width: 1280px)
      display: none
    span
      font-weight: 500
      font-size: 16px
    img
      width: 30px
