section.about .content
  align-items: center
  .pt1
    width: 85%
    margin: 3rem auto
    align-items: center
    @media (min-width: 768px)
      flex-direction: row
      justify-content: space-between
      margin: 3rem auto 5rem
      img
        width: 40%
    @media (min-width: 1024px)
      margin: 6rem auto
      img
        width: 50%
    @media (min-width: 1280px)
      margin: 5rem auto
      img
        width: 45%
    @media (min-width: 1440px)
      img
        width: 40%
    @media (min-width: 1440px)
      width: 75%
    @media (min-width: 1900px)
      width: 65%
    .resume
      align-items: flex-start
      h1
        font-weight: 400
        color: #020811
        text-transform: uppercase
        margin: 1rem 0 .5rem
        span
          font-size: 32px
          color: $mainColor
      p
        color: #020811
      @media (min-width: 768px)
        width: 55%
        h1
          margin: 0 0 1rem
      @media (min-width: 1024px)
        width: 45%
      @media (min-width: 1366px)
        h1 span
          font-size: 36px
      @media (min-width: 1600px)
        h1 span
          font-size: 40px
  .pt2
    align-items: center
    background: $mainColor
    .text
      padding: 5% 7.5%
    .text p, .text span
      font-size: 30px
      text-transform: uppercase
      text-align: left
    .text span
      font-weight: 600
    img
      width: 70%
    @media (min-width: 768px)
      width: 85%
      border-radius: 30px
      flex-direction: row
      justify-content: flex-start
      margin-top: 2rem
      .text
        width: 55%
        background: none
        // position: absolute
        // left: 7.5%
        // top: 50%
        // transform: translateY(-50%)
      .text p, .text span
        font-size: 22px
      img
        width: 200px
        position: absolute
        bottom: 0%
        right: 7.5%
    @media (min-width: 1024px)
      width: 75%
      .text p, .text span
        font-size: 26px
      img
        width: 250px
    @media (min-width: 1280px)
      width: 70%
      .text p, .text span
        font-size: 30px
      img
        width: 280px
    @media (min-width: 1366px)
      .text p, .text span
        font-size: 32px
    @media (min-width: 1440px)
      margin-top: 5rem
    @media (min-width: 1600px)
      width: 65%
      margin-top: 7rem
      img
        width: 300px
    @media (min-width: 1900px)
      width: 60%
      .text 
        width: 60%
      .text p, .text span
        font-size: 34px

section.mision
  @media (min-width: 768px)
    margin: 4% 0
  @media (min-width: 1024px)
    margin: 3% 0
  .content
    width: 100%
    align-items: center
    gap: 1rem
    @media (min-width: 768px)
      width: 80%
      margin: 0 auto
      flex-direction: row
      align-items: stretch
      justify-content: space-between
    @media (min-width: 1024px)
      width: 75%
    @media (min-width: 1280px)
      width: 70%
    @media (min-width: 1600px)
      width: 65%
    @media (min-width: 1900px)
      width: 60%
    .pt1, .pt2
      align-items: center
      width: 100%
      background-color: #f2f2f2
      padding: 2rem
      @media (min-width: 768px)
        padding: 1rem
        border-radius: 15px
      @media (min-width: 1024px)
        padding: 2rem
      @media (min-width: 1280px)
        width: 45%
      @media (min-width: 1440px)
        padding: 2.5rem
      h2
        text-transform: uppercase
        color: $mainColor 
        font-weight: 400
        letter-spacing: 5px
        line-height: 1
        @media (min-width: 1280px)
          font-size: 28px
        @media (min-width: 1440px)
          font-size: 30px
        @media (min-width: 1600px)
          font-size: 32px
      img
        width: 30px
        margin: .7rem 0
      p
        text-align: center
        color: #535353

// section.values
//   margin: 3rem 0
//   .container
//     align-items: center
//     .title
//       align-items: center
//       margin-bottom: 1rem
//       @media (min-width: 768px)
//         margin-bottom: 1.5rem
//       @media (min-width: 1440px)
//         margin-bottom: 2.2rem
//       img
//         width: 30px
//         margin-top: .5rem
//       h2
//         font-weight: 400
//         letter-spacing: 5px
//         @media (min-width: 1280px)
//           font-size: 28px
//         @media (min-width: 1440px)
//           font-size: 30px
//         @media (min-width: 1600px)
//           font-size: 32px
//       h2:first-child
//         color: $mainColor
//       h3:last-child
//         color: #020811
//     .sliderValues
//       width: 100%
//       @media (min-width: 1440px)
//         display: flex
//         gap: 2rem
//         flex-direction: row
//         align-items: baseline
//         justify-content: space-between
//       @media (min-width: 1600px)
//         gap: 3rem
//       .slick-slide
//         margin: 10px!important
//         @media (min-width: 768px)
//         margin: 10px 1rem!important
//       .item
//         display: flex
//         flex-direction: column
//         align-items: flex-start
//         gap: 6px
//         @media (min-width: 1440px)
//           gap: 10px
//         img
//           width: 18px
//         h3
//           font-weight: 600
//           color: $mainColor
//         p
//           color: #535353
//           text-align: left
//       .slick-dots
//         list-style-type: none
//         display: flex
//         justify-content: center
//         align-items: center
//         position: relative
//         z-index: 2
//         li
//           border-radius: 50%
//           width: 6px
//           height: 6px
//           background: #D9D9D9
//           margin-right: 10px
//           transition: .3s
//           button
//             visibility: hidden
//         .slick-active
//           width: 16px
//           height: 6px
//           border-radius: 8px
//           background: $mainColor