
@import url("../css/animate.css")
@import "@fancyapps/ui/dist/fancybox/fancybox.css"

@import url("../css/slick.css")
@import url("../css/jquery-modal.css")

@import components/variables

html
    //line-height: 0 !important
    overflow: scroll
    -webkit-overflow-scrolling: touch
    overflow-x: hidden

*
    font-family: $font-principal

body
    //background: #ffffff

.swal-button--confirm
  background: $mainColor !important

.swal-button:active
  background-color: $mainColor !important

.swal-button
  background-color: $mainColor !important
  color: #ffffff
  font-weight: bold

.swal-button--cancel
  color: $mainColor
  background-color: #ffffff !important
  border: 1px solid $mainColor !important

.swal-icon img
  width: 200px !important

.fancybox__backdrop
  background: rgba(24, 24, 27, 0.80)

@import components/base
@import components/mixins
@import components/adittionals
@import components/header
@import components/menuMob
@import components/modalFilter
@import components/footer
@import components/float
@import home/bannerHome
@import home/portfolio
@import home/ally
@import home/services
@import about/content
@import portfolio/products
@import portfolio/viewProduct
@import portfolio/modalShare
@import contact/content
@import terms/content

@import './login/content'
@import './files/content'


.grecaptcha-badge
  visibility: inherit

.loading
  width: 100%
  position: fixed
  z-index: 5
  top: 0px
  left: 0px
  text-align: center
  background: $mainColor
  height: 100vh
  div
    text-align: center
    position: fixed
    top: 45%
    width: 100%
    height: 80px
    img
      width: 200px
      @media screen and (max-width: 600px)
        width: 45%

.loading_none
  display: none