.slick-dots
  list-style-type: none
  display: flex
  justify-content: center
  align-items: center
  position: relative
  z-index: 2
  li
    border-radius: 50%
    width: 6px
    height: 6px
    background: $white
    margin-right: 10px
    transition: .3s
    button
      visibility: hidden
  .slick-active
    width: 16px
    height: 6px
    border-radius: 8px
    background: #D9D9D9

.blocker
  z-index: 3