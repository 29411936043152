*
  margin: 0
  padding: 0
  border: none
  outline: none
  box-sizing: border-box

html
  scroll-behavior: smooth

body
  max-width: 2000px
  margin: auto
  caret-color: $mainColor
  font-family: 'Rajdhani', sans-serif

img
  max-width: 100%
  height: auto
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -o-user-select: none
  user-select: none
  -webkit-user-drag: none
  -khtml-user-drag: none
  -moz-user-drag: none
  -o-user-drag: none

h1
  color: $white
  font-size: 32px
  @media (min-width: 1366px)
    font-size: 36px
  @media (min-width: 1600px)
    font-size: 40px
  @media (min-width: 1900px)
    font-size: 44px

a, p, span, li
  font-size: 14px
  color: $white
  @media (min-width: 1366px)
    font-size: 16px

p
  text-align: justify
  color: $white
  @media (min-width: 1900px)
    font-size: 18px

label, input textarea
  font-family: 'Montserrat', sans-serif

a
  text-decoration: none
  &:active
    -webkit-tap-highlight-color: transparent

.dnone
  display: none

.flex
  display: flex

.colflex
  display: flex
  flex-direction: column

.grid
  display: grid

.container
  width: 85%
  margin: auto

.relative
  position: relative

.absolute
  position: absolute