.floatWpp
  position: fixed
  z-index: 2
  bottom: 2rem
  right: 1rem
  background: $whatsapp
  place-items: center
  padding: 10px
  border-radius: 50%
  width: 45px
  img
    width: 100%