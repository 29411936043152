.contMob
  position: fixed
  top: 0
  left: -100%
  background-color: $mainColor
  width: 100%
  height: 100vh
  padding: 15% 5%
  z-index: 4
  transition: .5s
  align-items: center
  @media (min-width: 768px)
    width: 50%
  .content
    align-items: center
    height: 80%
    width: 80%
    justify-content: space-evenly
    .logob
      width: 80%
    .links a
      font-size: 20px
      font-weight: 700
      text-align: center
      &:not(:last-child)
        margin-bottom: 3rem
    .social
      align-items: center
      justify-content: center
      gap: 0 1.5rem
      img
        width: 100%
    
.show
  left: 0

.close
  width: 90%
  justify-content: flex-end
  img
    width: 30px
    height: auto
