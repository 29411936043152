section.login
  background-position: bottom center
  background-repeat: no-repeat
  background-size: cover
  padding: 10% 0
  @media (min-width: 768px)
    padding: 5% 0
  @media (min-width: 1440px)
    padding: 6% 0
  .container
    place-items: center
    @media (min-width: 768px)
      width: 40%
    @media (min-width: 1024px)
      width: 30%
    @media (min-width: 1280px)
      width: 25%
    @media (min-width: 1900px)
      width: 20%
  .container .form
    background: $white
    align-items: flex-start
    overflow: hidden
    border-radius: 20px
    width: 100%
    .tittle, .logow
      background: $mainColor
      place-items: center
      padding: 2rem 0
      width: 100%
    .tittle h2
      color: $white
      font-weight: 400
      font-size: 26px
    .logow img  
      width: 110px
    form
      width: 100%
      padding: 12% 10%
      align-items: flex-start
      label
        font-weight: 500
        color: $textColor
        font-size: 16px
        @media (min-width: 1280px)
          margin-bottom: 5px
      input
        width: 100%
        height: 2rem
        border-bottom: 1px solid #535353
        color: $textColor
        margin-bottom: 1rem
        transition: border-bottom .2s
        font-size: 16px
        &:focus
          border-bottom: 1px solid $mainColor
        @media (min-width: 1280px)
          font-size: 16px
          margin-bottom: 2rem
          
      a
        align-self: center
        background: $secondColor
        padding: 12px 1.5rem
        border-radius: 15px
        justify-content: center
        transition: .3s
        margin-top: 1rem
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.14) , 0px 1px 10px 0px rgba(0,0,0,0.12) , 0px 2px 4px -1px rgba(0,0,0,0.2) 
        img
          transition: .2s
        @media (min-width: 1280px)
          cursor: pointer
          &:hover
            box-shadow: 0px 8px 17px 2px rgba(0,0,0,0.14) , 0px 3px 14px 2px rgba(0,0,0,0.12) , 0px 5px 5px -3px rgba(0,0,0,0.2)  
            transform: scale(1.02)
            img 
              transform: scale(.85)
            
