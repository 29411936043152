footer
  background: $mainColor
  .container
    align-items: center
    padding: 3rem 0 2rem
    border-bottom: 1px solid $white
    @media (min-width: 768px)
      border: none
    @media (min-width: 1024px)
      width: 90%
      flex-direction: row
      align-items: flex-start
      justify-content: space-between
    @media (min-width: 1366px)
      width: 85%
  .logoFoo
    margin-bottom: 3rem
    @media (min-width: 1024px)
      margin: 0
    @media (min-width: 1600px)
      width: 220px
  .container .item,
  .container .qrInfo,
  .container .brochure
    align-items: center
  .container .item
    margin-bottom: 2rem
    @media (min-width: 1024px)
      align-items: flex-start
      margin: 0
    h3
      text-transform: uppercase
      color: $white
      margin-bottom: .5rem
      &:last-of-type
        margin-top: 2rem
    p
      margin-bottom: 5px
    .social
      align-items: center
      gap: 0 1.5rem
  .qrInfo .qr
    @media (min-width: 1280px)
      display: flex
      justify-content: center
      align-items: flex-start
      gap: 0 1rem
      margin-bottom: 1.5rem
      p
        font-weight: 500
        @media (min-width: 1900px)
          font-size: 16px
    img
      width: 70px
  .qrInfo .brochure
    align-items: center
    p
      font-weight: 500
      margin-bottom: .5rem
      font-size: 16px
    @media (min-width: 1366px)
      flex-direction: row
      align-items: center
      gap: 0 10px
      a
        width: 35px
      p
        margin: 0
  .gtnd
    align-items: center
    padding: 1rem 0
    span
      display: none
    p
      text-align: center
      font-weight: 500
      @media (min-width: 1366px)
        font-size: 14px
    .mark
      margin-top: 5px
    @media (min-width: 1440px)
      span, p
        font-size: 14px
    @media (min-width: 768px)
      width: 100%
      flex-direction: row
      justify-content: center
      border-top: 1px solid $white
      gap: 0 1rem
      span
        display: block
        font-weight: 600
      br
        display: none
      .mark
        flex-direction: row
        margin: 0
        gap: 0 5px
