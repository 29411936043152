section.terms
    padding: 15% 0%
    @media (min-width: 768px)
        padding: 5% 0%
section.terms .content
    width: 85% !important
    margin: 0 auto
    align-items: center
    display: block
    @media (min-width: 768px)
        width: 70% !important
    @media (min-width: 1024px)
        width: 90% !important
        flex-direction: row
        align-items: flex-start
        justify-content: space-between
    h1
        color: $mainColor
        text-transform: uppercase
        font-weight: 400
        letter-spacing: 4px
        text-align: center
        font-size: 22px
        @media (min-width: 1280px)
            font-size: 26px
        @media (min-width: 1440px)
            font-size: 30px
    p
        color: #020811
        font-size: 16px
        text-align: justify
        padding-top: 10%
        white-space: pre-wrap
        overflow-wrap: break-word
        @media (min-width: 768px)
            padding-top: 3%