section.products
  background-color: #131313
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  padding: 10% 0
  @media (min-width: 768px)
    padding: 8% 0
  @media (min-width: 1280px)
    padding: 6% 0
  @media (min-width: 1440px)
    padding: 4% 0
  .filterProducts
    margin: 1.5rem 0 2rem
    @media (min-width: 1440px)
      margin: 1.5rem 0 3rem
  .filterProducts .content
    width: 100%
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    @media (min-width: 1280px)
      width: 85%
      margin: 0 auto
      flex-wrap: nowrap
    @media (min-width: 1440px)
      width: 80%
    @media (min-width: 1900px)
      width: 75%
  .filter, .order
    align-items: center
    padding: 1rem
    border: 1px solid $secondColor
    border-radius: 1rem
    background: rgba(9, 78, 174, 0.10)
    gap: 0 1rem
    img
      width: 20px
  .filter
    margin-left: 7.5%
    @media (min-width: 1280px)
      margin: 0
  .order
    margin-right: 7.5%
    .select
      display: none
      bottom: -240%
      right: 0
      flex-direction: column
      align-items: flex-start
      width: 150%
      background: #00090C
      border: 1px solid $secondColor
      border-radius: 15px
      padding: 1rem 0  
      div
        cursor: pointer
        padding: 5px 1rem
        width: 100%
        &:hover
          background: rgba(37, 211, 102, 0.20)
    &:hover
      .select
        display: flex
    @media (min-width: 1280px)
      margin: 0
      order: 3
  .sliderFilter
    width: 100%
    order: 3
    margin-top: 2rem
    @media (min-width: 1280px)
      width: 60%
      order: 2
      margin: 0
      .arrow
        display: block
        z-index: 2
        top: 50%
        transform: translateY(-50%)
        cursor: pointer
      .fBack
        left: -10%
      .fNext
        right: -10%
  .sliderFilter .listFilters
    width: 100%
    .slick-slide
      margin: 0 1rem
    .item
      display: flex!important
      align-items: center
      justify-content: center
      width: 140px!important
      height: 130px!important
      border-radius: 20px
      background: rgba(9, 78, 174, .2)
      gap: 1rem 0
      transition: background-color .2s
      cursor: pointer
      span
        text-align: center
        font-size: 12px
        font-weight: 500
      &:hover
        background-color: $mainColor
section.products .container
  align-items: center
  .applied
    width: 100%
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap
    padding: 1rem
    background: rgba(255, 255, 255, 0.02)
    margin-bottom: 1rem
    gap: 1rem
    @media (min-width: 1280px)
      justify-content: center
    @media (min-width: 1600px)
      margin-bottom: 2rem
    .item
      align-items: center
      gap: 0 0.8rem
      padding: 5px 10px
      flex-shrink: 0
      border-radius: 10px
      border: 1px solid #535353
      cursor: pointer
      display: none
      p, span
        color: #c4c4c4
        font-weight: 500
        font-size: 12px
        @media (min-width: 1440px)
          font-size: 14px
    .item.delete
      border: 1px solid #F1003A
      p, span
        color: #F1003A
  .paginator
    width: 100%
    margin: 3rem 0 2rem
    align-items: center
    justify-content: center
    gap: 0 1.5rem
    @media (max-width: 600px)
      display: grid !important
      .numbers
        display: block !important
      img
        display: none !important
    img
      width: 35px
      cursor: pointer
    .numbers
      justify-content: space-around
      gap: 0 1.5rem
      a
        padding: 10px 15px
        border-radius: 10px
      a.selected
        border: 1px solid $secondColor
section.products .listProducts
  width: 100%
  gap: 2rem 0
  place-items: center
  grid-template-columns: 1fr
  @media (min-width: 768px)
    grid-template-columns: repeat(2, 1fr)
    gap: 3rem 0
  @media (min-width: 1024px)
    grid-template-columns: repeat(3, 1fr)
  @media (min-width: 1366px)
    grid-template-columns: repeat(4, 1fr)
  @media (min-width: 1900px)
    grid-template-columns: repeat(5, 1fr)
  .item
    max-width: 240px
    border-radius: 30px
    overflow: hidden
    background: rgba(9, 78, 174, 0.10)
    transition: background .3s
    &:hover
      background: rgba(9, 78, 174, 0.50)
    .thumb
      background: $white 
      img
        width: 100%
        object-fit: contain
        height: 250px
    .info
      align-items: center
      padding: 10%
      a:not(.goProduct)
        color: $white
        text-align: center
        margin: 1rem 0
        font-size: 18px
        &:hover
          text-decoration: underline
      .goProduct
        border-radius: 15px
        place-items: center
        padding: 10px 1.2rem
        border: 1px solid $secondColor
        transition: .2s
        &:hover
          background: $secondColor
          path
            fill: #020811

