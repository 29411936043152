section.files
  padding: 14% 0
  @media (max-width: 768px)
    padding: 6% 0
  @media (min-width: 1600px)
    padding: 10% 0
section.files .container
  align-items: center
  @media (min-width: 1024px)
    width: 65%
  @media (min-width: 1366px)
    width: 60%
  @media (min-width: 1600px)
    width: 50%
  form
    width: 100%
  .search
    width: 100%
    align-items: center
    justify-content: flex-start
    gap: 0 1rem
    margin: 0 auto
    margin-bottom: 2rem
    @media (min-width: 768px)
      width: 60%
    @media (min-width: 1024px)
      width: 75%
    label
      color: $textColor
      font-weight: 500
      font-size: 16px
    input
      border-radius: 15px
      border: 1px solid #c4c4c4
      padding: 0 10px
      flex: 1
      height: 2.5rem
      color: $textColor
  .listFiles
    width: 100%
    align-items: flex-start
    gap: 1.5rem 0
    @media (min-width: 1280px)
      gap: 5px 0
    .item
      width: 100%
      align-items: center
      justify-content: space-between
      border-radius: 15px
      gap: 0 10%
      @media (min-width: 1280px)
        border: 1px solid $white
        padding: .1rem
        border-radius: 20px
        transition: .3s
        &:hover
          border: 1px solid #c4c4c4
      p
        color: $textColor
        font-weight: 500
        text-align: left
        font-size: 16px
        @media (min-width: 1600px)
          font-size: 1rem
      a
        background: $textColor
        justify-content: center
        align-items: center
        border-radius: 15px
        padding: 10px
        span
          display: none
        @media (min-width: 768px)
          padding: 10px 1rem
          gap: 0 10px
          border-radius: 20px
          span
            display: inline-flex
            font-size: 14px
            font-weight: 500
            color: $white
        @media (min-width: 1280px)
          cursor: pointer
          transition: all .3s
          &:hover
            background: $mainColor
            span
              color: #18191A
            svg g path
              fill: #18191A
        @media (min-width: 1600px)
          span
            font-size: 14px
  .paginator
    width: 100%
    align-items: center
    justify-content: center
    gap: 0 2rem
    margin: 4rem auto 1rem
    @media (min-width: 768px)
      gap: 0 3rem
    &>img
      width: 30px
      @media (min-width: 1280px)
        cursor: pointer
    .numbers
      align-items: center
      gap: 0 2rem
      @media (min-width: 768px)
        gap: 0 3rem
      span
        @media (min-width: 768px)
          font-size: 16px
      span:not(.selected)
        color: $textColor
        &:hover
          text-decoration: underline
        @media (min-width: 1280px)
          cursor: pointer
      span.selected
        position: relative
        color: #18191A
        &::before
          content: ''
          position: absolute
          z-index: -1
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          width: 30px
          height: 30px
          border-radius: 50%
          background: $mainColor