section.ally 
  background: $mainColor
section.ally .content
  align-items: center
  @media (min-width: 1024px)
    flex-direction: row-reverse
    justify-content: space-between
    padding-left: 7.5%
  .image
    width: 100%
    @media (min-width: 1024px)
      width: 60%
    @media (min-width: 1280px)
      width: 55%
    @media (min-width: 1366px)
      width: 50%
    .worker
      bottom: 0
      left: 0
      z-index: 1
      width: 55%
    .symbol
      right: 0
      z-index: 2
      height: 100%
      width: auto
  .info
    width: 85%
    padding: 3rem 0
    align-items: flex-start
    @media (min-width: 1024px)
      width: 35%
      padding: 0
    h1
      font-weight: 400
      letter-spacing: 7px
      text-transform: uppercase
      line-height: 1.1
      span
        font-size: 32px
        @media (min-width: 1280px)
          font-weight: 600
        @media (min-width: 1366px)
          font-size: 36px
        @media (min-width: 1900px)
          font-size: 44px
    p
      margin: 1.5rem 0
    a
      display: flex
      background: $white
      border-radius: 20px
      align-items: center
      justify-content: space-between
      padding: 10px 12px
      gap: 0 1.5rem
      img
        width: 15px
      span
        color: #020811
        font-size: 16px
        font-weight: 500