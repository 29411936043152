header
  position: fixed
  z-index: 3
  width: 100%
  background: $mainColor
  box-shadow: 0 5px 20px 0 rgba(2, 8, 17, 0.10)
  .container
    justify-content: space-between
    align-items: center
    padding: 1.5rem 0
    .logo
      width: 140px
      @media (min-width: 1024px)
        width: 180px
      img
        width: 100%
    nav, .social
      display: none
      @media (min-width: 1024px)
        display: flex
        align-items: center
        gap: 0 2rem
    nav
      @media (min-width: 1280px)
        gap: 0 6rem
      a
        font-weight: 500
        text-transform: uppercase
        @media (min-width: 1600px)
          font-size: 18px
    .menu
      width: 25px
      @media (min-width: 1024px)
        display: none

.separatorHeader
  width: 100%